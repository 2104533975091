import React from 'react'
import "@fontsource/quicksand"
import "../styles/index.scss"
import Header from './Header'
import Footer from './Footer'

const Layout = (props) =>{
    return (
        <>
          <Header class={ props.class }/>
          { props.children }
          <Footer/>
        </>
    )
}

export default Layout
