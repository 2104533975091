import { Link } from 'gatsby'
import React from 'react'

import * as footerStyles from '../styles/footer.module.scss'

function Footer() {
    return (
        <footer className={ footerStyles.footer }>
            <nav>
                <ul>
                    <li>
                        <Link to="/about">ABOUT ME</Link>
                    </li>
                    <li>
                        <Link to="/projects">PROJECTS</Link>
                    </li>
                    <li>
                        <Link to="/logs">DEV LOGS</Link>
                    </li>
                    <li>
                        <Link to="/contact">CONTACT ME</Link>
                    </li>
                </ul>
            </nav>
            <div className={ footerStyles.socials }>
                <div>
                    <ul>
                        <li>
                            <a href="https://www.linkedin.com/in/monica-meraz/">LinkedIn</a>
                        </li>
                        <li>
                            <a href="https://github.com/MM-develops">GitHub</a>
                        </li>
                    </ul>
                </div>
                <Link to="/" className={ footerStyles.logo + " logo" }>MM</Link>
            </div>
        </footer>
    )
}

export default Footer
