import { Link } from 'gatsby'
import React, { useState } from 'react'

import * as headerStyles from '../styles/header.module.scss'

class Header extends React.Component {

    constructor(){
        super();
        this.state = {
            mobile: false
        };
        this.handleMenu = this.handleMenu.bind(this);
    }

    handleMenu(){
        let windowWidth = window.innerWidth;
        if( windowWidth < 800){
            this.setState({mobile: true});
        }else{
            this.setState({mobile: false});
        }
    }
    
    componentDidMount(){
        this.handleMenu();
        window.addEventListener("resize", this.handleMenu );
    }

    render()
    {
        if( this.state.mobile ) {
            return <MobileMenu class={ this.props.class }/>
        }
        else {
            return <HeaderMenu class={ this.props.class }/>
        }
    }
}

export default Header

function MobileMenu(props){

    const [opened, setOpened] = useState('');
    const clickToggleOpened = () => {
        if(opened === ''){
            setOpened('opened');
        }else{
            setOpened('');
        }
    }
    const onKeyToggleOpened = (e) => {
        if(e.keyCode === 13){
            if(opened === ''){
                setOpened('opened');
            }else{
                setOpened('');
            }
        }
    }
    return(
        <>
            <div className={ headerStyles.mobileMenu  + ' collapse ' + opened }>
                <ol className={ headerStyles.main }>
                    <li>
                        <Link to="/about">ABOUT ME</Link>
                    </li>
                    <li>
                        <Link to="/projects">PROJECTS</Link>
                    </li>
                    <li>
                        <Link to="/logs">DEV LOGS</Link>
                    </li>
                    <li>
                        <Link to="/contact">CONTACT ME</Link>
                    </li>
                </ol>
                <ul  className={ headerStyles.socials }>
                    <li>
                        <a href="https://www.linkedin.com/in/monica-meraz/">LinkedIn</a>
                    </li>
                    <li>
                        <a href="https://github.com/MM-develops">GtiHub</a>
                    </li>
                </ul>
            </div>
            <header className={ props.class + ' ' + opened }>
                <nav className={ headerStyles.nav }>
                    <Link to="/" className={ headerStyles.logo + " logo" }>MM</Link>
                    <div onClick={ clickToggleOpened } onKeyDown={ onKeyToggleOpened } role="button" tabIndex={0}>
                        <div className={ headerStyles.icon + ' icon ' + opened }></div>
                    </div>
                </nav>
            </header>
            <div className={"spacer " + opened}></div>
        </>
    )
}

function HeaderMenu(props){
    return(
        <header className={ props.class }>
            <nav className={ headerStyles.nav }>
                <Link to="/" className={ headerStyles.logo + " logo" }>MM</Link>
                <ol className={ headerStyles.main }>
                    <li>
                        <Link to="/about">ABOUT ME</Link>
                    </li>
                    <li>
                        <Link to="/projects">PROJECTS</Link>
                    </li>
                    <li>
                        <Link to="/logs">DEV LOGS</Link>
                    </li>
                    <li>
                        <Link to="/contact">CONTACT ME</Link>
                    </li>
                </ol>
            </nav>
        </header>
    )
}